import Button from '@/components/Button';
import Form from '@/components/Form';
import Input from '@/components/Input';
import { OMTContext } from '@/hooks/onlineMembershipTemplates/omtContext';
import { useGeneralMembershipTemplates } from '@/hooks/useAppsite';
import useBackends from '@/hooks/useBackends';
import { useDSB } from '@/hooks/useDSB';
import { useHasFeature } from '@/hooks/useFeatureFlags';
import useFeedback from '@/hooks/useFeedback';
import { Fee } from '@/hooks/useOnlineMembershipTemplates';
import useSdk from '@/hooks/useSdk';
import { price, TabsContext } from '@/routes/OnlineMembershipTemplateDetail';
import { classNames } from '@/util/classNames';
import { euroToCent } from '@/util/currency';
import { MembershipTemplate } from '@entrecode/dm-types/types/MembershipTemplate';
import { MembershipTemplateVersion } from '@entrecode/dm-types/types/MembershipTemplateVersion';
import { formatPeriod, formatPrice, formatRecurring } from '@entrecode/hectorone-helpers';
import {
  CurrencyEuroIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  SquaresPlusIcon,
} from '@heroicons/react/24/outline';
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import EntryResource from 'ec.sdk/lib/resources/publicAPI/EntryResource';
import nunjucks from 'nunjucks';
import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { NumericFormat } from 'react-number-format';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import { AssetPickerInput } from '../AssetPickerInput';
import BackendSelect from '../BackendSelect';
import { DateInput } from '../CalendarInput';
import CopyLink from '../CopyLink';
import LegalEntitySelect from '../LegalEntitySelect';
import MakroEditor from '../MakroEditor/MakroEditor';
import Modal from '../Modal';
import SelectInput from '../SelectInput';
import SimpleSelect from '../SimpleSelect';
import { ToggleInput } from '../Toggle';
import Tooly from '../Tooly';
import { hectorIntervals } from './hectorIntervals';

export function PricesForm({
  isVoucher = false,
  onSave,
}: {
  template?: MembershipTemplate;
  isVoucher?: boolean;
  onSave?: (values: any) => void;
}): JSX.Element {
  const { membershipTemplate, selectedVersion, isAddon } = useContext(OMTContext);

  if (!(membershipTemplate && selectedVersion))
    return (
      <div>
        <div className={'grid grid-cols-[1fr_2fr] px-2 border-b gap-6 pb-6 my-6'}>
          <Skeleton className="w-10" />
          <Skeleton className="w-full h-12" />
        </div>
        <div className={'grid grid-cols-[1fr_2fr] px-2 border-b gap-6 pb-6 my-6'}>
          <Skeleton className="w-10" />
          <Skeleton className="w-full h-12" />
        </div>
        <div className={'grid grid-cols-[1fr_2fr] px-2 border-b gap-6 pb-6 my-6'}>
          <Skeleton className="w-10" />
          <Skeleton className="w-full h-12" />
        </div>
        <div className={'grid grid-cols-[1fr_2fr] px-2 border-b gap-6 pb-6 my-6'}>
          <Skeleton className="w-10" />
          <Skeleton className="w-full h-12" />
        </div>
      </div>
    );

  return (
    <>
      <Templ
        onSave={onSave}
        isVoucher={isVoucher}
        data={{
          membership_template: membershipTemplate,
          membership_template_version: selectedVersion,
        }}
      />
    </>
  );
}

const Templ = ({
  data,
  isVoucher = false,
  onSave,
}: {
  data: {
    membership_template: MembershipTemplate & EntryResource;
    membership_template_version: MembershipTemplateVersion;
  };
  onSave?: (values: any) => void;
}) => {
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      membership_template: { ...data.membership_template },
      membership_template_version: {
        ...data.membership_template_version,
        // renewal:
        //   data.membership_template_version.renewal === 'noRenew' ? null : data.membership_template_version.renewal,
        earliestStartDateUsed: data.membership_template_version.earliestStartDate ? true : null,
      },
    },
  });

  const navigate = useNavigate();
  const { setCanChange } = useContext(TabsContext);
  const { isVoucher: isVoucherUrl, isAddon, isAbo } = useContext(OMTContext);
  const isDirty = form.formState.isDirty;

  isVoucher = isVoucher || isVoucherUrl;

  useEffect(() => {
    setCanChange(!isDirty);
  }, [isDirty, setCanChange]);

  const { userCan, templateID, changeStatus } = useContext(OMTContext);
  const { backends } = useBackends();
  const { withFeedback, pending } = useFeedback();
  const { api } = useSdk();
  const { post } = useDSB();
  const onSubmit = (values) => {
    onSave
      ? onSave?.(values)
      : withFeedback(
          async () => {
            data.membership_template.name = values.membership_template.name;
            if (backends.length === 1) {
              data.membership_template.backendId = Object.keys(backends)[0];
            } else {
              data.membership_template.backendId = values.membership_template.backendId;
            }
            data.membership_template.type = values.membership_template.type ?? 'default';
            data.membership_template_version.minimumMembershipAge =
              values.membership_template_version.minimumMembershipAge;
            data.membership_template_version.maximumMembershipAge =
              values.membership_template_version.maximumMembershipAge;
            data.membership_template_version.trialPeriod =
              values.membership_template_version.trialPeriod === 'PW'
                ? null
                : values.membership_template_version.trialPeriod;
            data.membership_template_version.mainIntervalPrice = values.membership_template_version.mainIntervalPrice;
            data.membership_template_version.mainDuration = values.membership_template_version.mainDuration;
            data.membership_template_version.mainInterval = values.membership_template_version.mainInterval;
            data.membership_template_version.discountIntervalPrice = Number.isNaN(
              values.membership_template_version.discountIntervalPrice,
            )
              ? null
              : values.membership_template_version.discountIntervalPrice ?? null;
            data.membership_template_version.discountDuration =
              values.membership_template_version.discountIntervalPrice !== null
                ? values.membership_template_version.discountDuration ?? null
                : null;
            data.membership_template_version.discountInterval = values.membership_template_version.discountInterval;

            data.membership_template_version.discounts = [];
            for (const index in values.membership_template_version.discounts) {
              const discount = values.membership_template_version.discounts[index];
              if (discount.discountType === 'youth') {
                delete discount.duration;
                discount.age = Number.parseInt(discount.age, 10);
              } else {
                delete discount.age;
              }
              data.membership_template_version.discounts[index] = {
                ...discount,
                price: discount.price,
              };
            }
            data.membership_template_version.discountsMultiple =
              values.membership_template_version.discountsMultiple ?? false;
            data.membership_template_version.fees = values.membership_template_version.fees;
            data.membership_template_version.addons = values.membership_template_version.addons;
            data.membership_template_version.mainText = values.membership_template_version.mainText;
            data.membership_template_version.benefits = values.membership_template_version.benefits;
            data.membership_template_version.hectorSettings = values.membership_template_version.hectorSettings;

            data.membership_template_version.renewal = values.membership_template_version.renewal || 'noRenew';
            data.membership_template_version.earliestStartDate =
              values.membership_template_version.earliestStartDate &&
              dayjs(values.membership_template_version.earliestStartDate).format('YYYY-MM-DD');

            if (isAddon) {
              delete data.membership_template.type;
              data.membership_template.description = values.membership_template.description;

              data.membership_template.images = values.membership_template.images;
              data.membership_template.salesDescription = values.membership_template.salesDescription;
              data.membership_template.profileActive = values.membership_template.profileActive;
              data.membership_template.upsellingActive = values.membership_template.upsellingActive;
              data.membership_template.terminalActive = values.membership_template.terminalActive || false;
            }

            //@ts-ignore
            if (data.membership_template._id && data.membership_template_version._id) {
              //@ts-ignore
              await data.membership_template_version.save(true);
              //@ts-ignore
              await data.membership_template.save(true);
            } else {
              let res = await post('admin/membership-templates', {
                name: data.membership_template.name,
                backendId: data.membership_template.backendId,
                type: data.membership_template.type,
                version: data.membership_template_version,
                mode: isAddon ? 'addon' : 'abo',
                data: data.membership_template,
              });

              const entry = await api.entry('membership_template_version', res.draft.id);
              const newComment = await api.createEntry('comment', {
                text: '{system} Neuer Entwurf wurde erstellt',
              });

              entry.comments.push(newComment);
              await entry.save(true);

              setCanChange(true);
              //@ts-ignore
              navigate(`../${res.id}?tab=0&saved`);
            }

            setCanChange(true);
          },
          {
            success: 'Erfolgreich gespeichert',
            error: (err) => {
              console.log(err);
              return 'Fehler beim Speichern';
            },
          },
        );
  };

  const replace = insertVariables({
    ...form.watch('membership_template_version'),
  });

  const mainInterval = form.watch('membership_template_version.mainInterval');
  useEffect(() => {
    // If mainInterval changed, update discountInterval

    if (
      form.getFieldState('membership_template_version.mainInterval').isDirty &&
      !form.getFieldState('membership_template_version.discountInterval').isDirty
    ) {
      form.setValue('membership_template_version.discountInterval', mainInterval);
    }
  }, [mainInterval, form]);

  const discountInter = form.watch('membership_template_version.discountInterval');
  useEffect(() => {
    if (
      form.getFieldState('membership_template_version.discountInterval').isDirty &&
      !form.getFieldState('membership_template_version.discountDuration').isDirty
    ) {
      form.resetField('membership_template_version.discountDuration');
      form.setValue('membership_template_version.discountDuration', discountInter);
    }
  }, [discountInter, form]);

  const intervalPrice = form.watch('membership_template_version.discountIntervalPrice');
  useEffect(() => {
    if (intervalPrice && !discountInter) {
      form.setValue('membership_template_version.discountInterval', 'P1M');
    }
  }, [intervalPrice]);

  const [search] = useSearchParams();
  let {
    data: templateTexts,
    mutate,
    isLoading,
  } = useSWR(
    api && form.watch('membership_template.backendId')
      ? `${api.shortID}/text-templates/${form.watch('membership_template.backendId')}`
      : null,
    async () => {
      return (
        await api.entryList('membership_template_version_texts', {
          backendId: form.watch('membership_template.backendId'),
        })
      ).getFirstItem();
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  const { data: templateTextsGlobal } = useSWR(
    api && form.watch('membership_template.backendId') ? `${api.shortID}/text-templates/global` : null,
    async () => {
      return (
        await api.entryList('membership_template_version_texts', {
          backendId: { null: true },
        })
      ).getFirstItem();
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  templateTexts = useMemo(() => {
    if (!templateTexts) {
      // if no backend specific texts are available, use global texts
      return templateTextsGlobal;
    }

    const res = Object.entries(templateTexts?.config || {}).reduce((acc, [key, value]) => {
      if (!value || value.length === 0) {
        /* merge with templateTextsGlobal, as fallback */
        acc[key] = templateTextsGlobal?.config[key];
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

    return { ...templateTexts, config: res };
  }, [templateTexts, templateTextsGlobal]);

  const defaultVorteile = () => {
    const replace = insertVariables({
      ...form.watch('membership_template_version'),
    });

    form.setValue(
      'membership_template_version.benefits',
      templateTexts?.config.benefits
        ?.filter((t) => {
          if (t.forAddons) return true;
          if (!t.showWhenSet) return true;
          const a = t.showWhenSet?.map((s) => {
            return form.watch(`membership_template_version.${s}`);
          });
          const allSet = a?.every((b) => b);
          return allSet;
        })
        ?.flatMap((benefit, i) => {
          if (benefit.forAddons === true) {
            return form.watch('membership_template_version').addons.map((addon) => {
              return {
                title: insertVariables({ addon })(benefit.title),
                description: insertVariables({ addon })(benefit.description),
              };
            });
          }
          if (benefit.forAddons === 'discount') {
            return form.watch('membership_template_version').discounts.map((discount) => {
              return {
                title: insertVariables({ discount })(benefit.title),
                description: insertVariables({ discount })(benefit.description),
              };
            });
          }

          if (benefit.forAddons === 'fee') {
            return form.watch('membership_template_version').fees.map((fee) => {
              return {
                title: insertVariables({ fee })(benefit.title),
                description: insertVariables({ fee })(benefit.description),
              };
            });
          }
          return {
            title: replace(benefit.title),
            description: replace(benefit.description),
          };
        }),
      {
        shouldDirty: true,
      },
    );
  };

  const { data: generalTemplates } = useGeneralMembershipTemplates(form.watch('membership_template')?.backendId);

  const [showFeePicker, setShowFeePicker] = useState(false);

  const defaultFees = templateTexts?.config?.fees?.map((fee, i) => {
    const a = fee.showWhenSet?.map((s) => {
      return form.watch(`membership_template_version.${s}`);
    });
    const allSet = fee.showWhenSet ? a?.every((b) => b) : true;
    return (
      <Button
        key={i}
        $disabled={!allSet}
        onClick={() => {
          form.setValue(
            'membership_template_version.fees',
            [
              ...form.watch('membership_template_version.fees'),
              {
                title: replace(fee.title),
                name: fee.name,
                body: replace(fee.body),
                price: fee.price,
                interval: fee.interval,
                due: fee.due,
              } as Fee,
            ],
            { shouldDirty: true },
          );
          setShowFeePicker(false);
        }}
      >
        {fee.name}
      </Button>
    );
  });

  const [showAddonPicker, setShowAddonPicker] = useState(false);
  const [addonQueue, setAddonQueue] = useState({});
  const defaultAddons = templateTexts?.config.addons?.map((addon, i) => {
    return {
      name: addon.name,
      description: insertVariables({ addon })(addon.description),
      interval: addon.interval,
      price: addon.price,
      trialPeriod: addon.trialPeriod,
      displayInterval: addon.displayInterval,
      displayPrice: addon.displayPrice,
    };
  });

  const backendPreseted = search.get('backend');
  const { hasFeature } = useHasFeature();

  return (
    <div
      className={classNames(
        'bg-white dark:bg-gray-700 p-4 rounded-lg',
        (!userCan.edit || data.membership_template_version?.draftStatus === null) && ' pointer-events-none',
      )}
    >
      <Column title={isAddon ? 'ADD-ON * ' : 'Abo *'}>
        <Field
          label={
            <div className="flex gap-1.5 items-center">
              {isAddon ? 'Name des ADD-ON' : 'Name des Abos'}
              <Tooly placement="right" label="Wird am Vertrag ausgegeben.">
                <InformationCircleIcon className="h-4 w-4" />
              </Tooly>
            </div>
          }
        >
          <Input type="text" {...form.register('membership_template.name', { required: true })} />
          {form.formState.errors.membership_template?.name && <small className="text-red-500">Pflichtfeld</small>}
        </Field>
        <div className={classNames(backendPreseted && 'cursor-not-allowed opacity-50  pointer-events-none')}>
          <Field label={hasFeature('ho-stripe') ? 'Betreiber' : 'Backend'}>
            <Controller
              control={form.control}
              rules={{ required: true }}
              name="membership_template.backendId"
              render={({ field }) => (
                <>
                  {hasFeature('ho-stripe') ? (
                    <LegalEntitySelect
                      setIfOnlyOne
                      listIfEmpty
                      excludeEmpty
                      persistAs={templateID ? undefined : 'backend'}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  ) : (
                    <BackendSelect
                      setIfOnlyOne
                      listIfEmpty
                      excludeEmpty
                      persistAs={templateID ? undefined : 'backend'}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                </>
              )}
            />
            {form.formState.errors.membership_template?.backendId && (
              <small className="text-red-500">Pflichtfeld</small>
            )}
          </Field>
        </div>
      </Column>

      {isAbo && (
        <Column title="Art*">
          <Field label="">
            <SelectInput
              control={form.control}
              name="membership_template.type"
              items={[
                {
                  label: 'Standard',
                  value: 'default',
                },
                {
                  label: 'Comeback',
                  value: 'comeback',
                },
                {
                  label: 'Freunde werben',
                  value: 'referral',
                },
              ]}
            />
            {form.formState.errors.membership_template_version?.minimumMembershipAge && (
              <small className="text-red-500">Pflichtfeld</small>
            )}
          </Field>
        </Column>
      )}

      <Column title="Alter*">
        <Field
          label={
            <div className="flex items-center gap-1">
              Mindestalter
              <Tooly placement="right" label="Mindestalter, mit dem der Rabatt ausgewählt und gebucht werden darf.">
                <InformationCircleIcon className="h-4 w-4" />
              </Tooly>
            </div>
          }
        >
          <Input
            type="text"
            {...form.register('membership_template_version.minimumMembershipAge', {
              valueAsNumber: true,
              required: true,
            })}
          />
          {form.formState.errors.membership_template_version?.minimumMembershipAge && (
            <small className="text-red-500">Pflichtfeld</small>
          )}
        </Field>
        <Field
          label={
            <div className="flex items-center gap-1">
              Maximales Alter (optional)
              <Tooly
                placement="left"
                label={
                  <span>
                    Maximalalter, mit dem der Rabatt ausgewählt und gebucht werden darf.
                    <br />
                    Bei einem “U18”-Altersrabatt muss 17 als Mindestalter hinterlegt werden, da Personen bis
                    einschließlich 17 den Rabatt buchen können.
                  </span>
                }
              >
                <InformationCircleIcon className="h-4 w-4" />
              </Tooly>
            </div>
          }
        >
          <Input
            type="text"
            {...form.register('membership_template_version.maximumMembershipAge', {
              valueAsNumber: true,
            })}
          />
          {form.formState.errors.membership_template_version?.maximumMembershipAge && (
            <small className="text-red-500">Pflichtfeld</small>
          )}
        </Field>
      </Column>

      {form.watch('membership_template.type') === 'referral' && (
        <Column title="Weiterempfehlungs-Prämie (10€)">
          <Controller
            render={({ field }) => (
              <div className="flex gap-2 w-full">
                <div className="w-full flex-1">
                  <SimpleSelect
                    items={[
                      { label: 'nach Testphase', value: 'afterTrial' },
                      { label: 'bei Abschluss', value: 'onMembershipSubmit' },
                      { label: 'keine', value: 'none' },
                    ]}
                    value={field.value?.value}
                    onChange={(v) => field.onChange({ type: 'referral', value: v })}
                  />
                </div>
                {field.value !== null && (
                  <Button.Action tooltip="Feld leeren" onClick={() => field.onChange(null)}>
                    <XMarkIcon className="h-5 w-5" />
                  </Button.Action>
                )}
              </div>
            )}
            control={form.control}
            name="membership_template_version.hectorSettings.bonus"
          />
        </Column>
      )}

      {form.watch('membership_template.type') === 'comeback' && (
        <Column title="Welcome-Back-Prämie">
          <Input.PricePerInterval
            price={form.register('membership_template_version.hectorSettings.bonus.value', {
              valueAsNumber: true,
              required: true,
            })}
            overrideOptions={[{ label: 'einmalig', value: 'NULL' }]}
            interval={() => {}}
          />
          {form.formState.errors.membership_template_version?.hectorSettings?.bonus?.value && (
            <small className="text-red-500 col-start-1">Pflichtfeld</small>
          )}
        </Column>
      )}

      <Column title="Testphase*">
        <Field label="">
          <Input.TimeInterval
            overrideOptions={[
              { label: 'keine', value: 'NULL' },
              { label: 'Tag/e', value: 'D' },
              { label: 'Woche/n', value: 'W' },
              { label: 'Monat/e', value: 'M' },
            ]}
            {...form.register('membership_template_version.trialPeriod', {
              setValueAs: (v) => (v?.includes('NULL') ? null : v),
            })}
          />

          {form.formState.errors.membership_template_version?.trialPeriod && (
            <small className="text-red-500">Pflichtfeld</small>
          )}
        </Field>
      </Column>

      <Column title={isAddon ? 'Regulärer Preis*' : ' Regulärer Mitgliedsbeitrag*'}>
        <Field label="Preis">
          <Input.PricePerInterval
            price={form.register('membership_template_version.mainIntervalPrice', {
              valueAsNumber: true,
              required: true,
            })}
            interval={form.register('membership_template_version.mainInterval', {
              setValueAs: (v) => (v === 'NULL' ? null : v),
              // required: true,
            })}
            overrideOptions={[
              { label: 'pro Tag', value: 'P1D' },
              { label: 'pro Woche', value: 'P1W' },
              { label: '2-wöchentlich', value: 'P2W' },
              { label: '4-wöchentlich', value: 'P4W' },
              { label: 'pro Kalendermonat', value: 'P1M' },
              { label: 'pro Vierteljahr', value: 'P3M' },
              { label: 'pro Halbjahr', value: 'P6M' },
              { label: 'pro Jahr', value: 'P1Y' },
            ]}
          />
          {form.formState.errors.membership_template_version?.mainIntervalPrice && (
            <small className="text-red-500">Preis eingeben</small>
          )}
          {form.formState.errors.membership_template_version?.mainInterval && (
            <small className="text-red-500">Intervall auswählen</small>
          )}
          {form.watch('membership_template_version.mainInterval') === 'P1M' && (
            <small>
              entspricht {price(form.watch('membership_template_version.mainIntervalPrice') / 4.33)} pro Woche
            </small>
          )}
        </Field>
      </Column>
      <Column title="Beginn der Mitgliedschaft">
        <Field label="Mitgliedschaft startet">
          <SelectInput
            control={form.control}
            name="membership_template_version.earliestStartDateUsed"
            items={[
              { label: 'ab Buchung', value: null },
              { label: 'an einem bestimmten Datum', value: true },
            ]}
          />
        </Field>
        {form.watch('membership_template_version.earliestStartDateUsed') && (
          <Field label="Start der Mitgliedschaft">
            <DateInput control={form.control} name="membership_template_version.earliestStartDate" />
          </Field>
        )}
      </Column>
      <Column title="Laufzeit">
        <Field label="Laufzeit">
          <Input.TimeInterval
            // overrideOptions={[
            //   { label: 'Woche/n', value: 'W' },
            //   { label: 'Monat/e', value: 'M' },
            // ]}
            {...form.register('membership_template_version.mainDuration', {
              required: true,
              validate: (value) => {
                if (value == 'PM') return 'Pflichtfeld';
                return true;
              },
              setValueAs: (v) => (v === 'NULL' ? null : v),
            })}
          />
          {form.formState.errors.membership_template_version?.mainDuration && (
            <small className="text-red-500">Pflichtfeld</small>
          )}
        </Field>
        <Field label="Laufzeit-Optionen">
          <SelectInput
            control={form.control}
            name="membership_template_version.renewal"
            items={[
              { label: 'Mitgliedschaft endet automatisch nach Laufzeit', value: 'noRenew' },
              { label: 'Mitgliedschaft verlängert sich automatisch', value: 'autoRenew' },
            ]}
          />
        </Field>
      </Column>

      <Column border={form.watch('membership_template_version.discountIntervalPrice') ? false : true} title="Aktion">
        <Field label="Aktionspreis">
          <Input.PricePerInterval
            price={form.register('membership_template_version.discountIntervalPrice', {
              valueAsNumber: true,
              validate: (v) => {
                if (v < 0) return 'Aktionspreis darf nicht negativ sein';
                return true;
              },
            })}
            interval={form.register('membership_template_version.discountInterval', {
              setValueAs: (v) => (v === 'NULL' ? null : v),
              // required: 'Pflichtfeld (Zeitraum)',
            })}
            overrideOptions={hectorIntervals}
          />

          {form.formState.errors.membership_template_version?.discountIntervalPrice && (
            <small className="text-red-500">
              {form.formState.errors.membership_template_version?.discountIntervalPrice.message}
            </small>
          )}
          {form.formState.errors.membership_template_version?.discountInterval && (
            <small className="text-red-500">
              {form.formState.errors.membership_template_version?.discountInterval.message}
            </small>
          )}
          {form.watch('membership_template_version.discountInterval') ===
            form.watch('membership_template_version.mainInterval') &&
          form.watch('membership_template_version.discountIntervalPrice') &&
          form.watch('membership_template_version.mainIntervalPrice') ? (
            <div className="text-gray-500 relative  text-xs">
              <CopyLink
                value={formatPrice(
                  +form.watch('membership_template_version.discountIntervalPrice') -
                    +form.watch('membership_template_version.mainIntervalPrice'),
                )}
              >
                {formatPrice(
                  +form.watch('membership_template_version.discountIntervalPrice') -
                    +form.watch('membership_template_version.mainIntervalPrice'),
                )}{' '}
              </CopyLink>
              Ersparnis gegenüber regulärem Mitgliedsbeitrag
            </div>
          ) : null}
        </Field>
        {typeof form.watch('membership_template_version.discountIntervalPrice') === 'number' &&
        form.watch('membership_template_version.discountIntervalPrice') >= 0 ? (
          <Field label="Aktionszeitraum">
            <Input.TimeInterval
              // overrideOptions={[

              //   { label: 'Woche/n', value: 'W' },
              //   { label: 'Monat/e', value: 'M' },
              // ]}
              {...form.register('membership_template_version.discountDuration', {
                required: true,
                setValueAs: (v) => {
                  if (v?.length < 3) return null;
                  return v === 'NULL' ? null : v;
                },
                shouldUnregister: true,
              })}
            />
            {form.formState.errors.membership_template_version?.discountDuration && (
              <small className="text-red-500">Pflichtfeld</small>
            )}
          </Field>
        ) : null}
      </Column>
      {isAddon && (
        <Column title="Einstellungen">
          <div className="flex flex-col gap-3 w-full col-span-2">
            <Field label="Buchung">
              <SelectInput
                control={form.control}
                name="membership_template_version.hectorSettings.addonBooking"
                items={[
                  {
                    label: 'Wöchentlich',
                    value: 'weekly',
                  },
                  {
                    label: 'Monatlich',
                    value: 'monthly',
                  },
                ]}
              />
              {form.formState.errors.membership_template_version?.minimumMembershipAge && (
                <small className="text-red-500">Pflichtfeld</small>
              )}
            </Field>

            <div className="flex justify-between my-3">
              <ToggleInput
                label="Im Mitgliederbereich aktivieren"
                defaultValue={false}
                control={form.control}
                name="membership_template.profileActive"
              />
              <ToggleInput
                label="Upselling aktivieren"
                defaultValue={false}
                control={form.control}
                name="membership_template.upsellingActive"
              />
              {/* <ToggleInput */}
              {/*   label="Terminal aktivieren" */}
              {/*   defaultValue={false} */}
              {/*   control={form.control} */}
              {/*   name="membership_template.terminalActive" */}
              {/* /> */}
            </div>

            <Field label="Beschreibung">
              <Input.Textarea {...form.register('membership_template.description')} />
            </Field>

            <Field label="Bilder">
              <AssetPickerInput group="settings-images" control={form.control} name="membership_template.images" />
            </Field>
            <Field label="Verkaufs Beschreibung">
              <Input.Textarea {...form.register('membership_template.salesDescription')} />
            </Field>

            <Field label="Einzelbezug">
              <Input.Textarea {...form.register('membership_template_version.hectorSettings.addonSingle')} />
            </Field>
            <Field label="Inkludierte Leistungen">
              <Input.Textarea {...form.register('membership_template_version.hectorSettings.addonIncludedServices')} />
            </Field>
            <Field label="Zusätzliche zu kaufende Artikel">
              <Input.Textarea
                {...form.register('membership_template_version.hectorSettings.addonAdditionalProducts')}
              />
            </Field>
          </div>
        </Column>
      )}

      {!isAddon && (
        <>
          <Column title="Rabatte">
            <div className="col-span-2">
              <Form.Item.SortableList
                form={form}
                name="membership_template_version.discounts"
                append={
                  userCan.edit && {
                    name: '',
                    description: '',
                    templateId: '',
                    templateType: 'Default',
                    price: '0',
                    discountType: 'youth',
                    age: null,
                  }
                }
                appendLabel="Rabatt hinzufügen"
                extraAction={
                  form.watch('membership_template_version.discounts')?.length > 1 &&
                  userCan.edit && (
                    <ToggleInput
                      small
                      label="Auswahl von mehreren Rabatten erlauben"
                      defaultValue={false}
                      control={form.control}
                      name="membership_template_version.discountsMultiple"
                    />
                  )
                }
              >
                {(i) => (
                  <Field className="p-6">
                    <Form.Item $first $dense>
                      <Form.Item.Label>Rabatt Art </Form.Item.Label>
                      <SelectInput
                        name={`membership_template_version.discounts.${i}.discountType`}
                        control={form.control}
                        items={[
                          {
                            label: 'Jugendrabatt',
                            value: 'youth',
                          },
                          {
                            label: 'Treuebonus',
                            value: 'loyalty',
                          },
                        ]}
                      />

                      {form.formState.errors.membership_template_version?.discounts?.[i]?.templateType && (
                        <small className="text-red-500">Pflichtfeld</small>
                      )}
                    </Form.Item>

                    <Form.Item $first $dense>
                      <Form.Item.Label>Name</Form.Item.Label>
                      <Input
                        type="text"
                        {...form.register(`membership_template_version.discounts.${i}.name`, { required: true })}
                      />

                      {form.formState.errors.membership_template_version?.discounts?.[i]?.name && (
                        <small className="text-red-500">Pflichtfeld</small>
                      )}
                    </Form.Item>

                    <Form.Item $first $dense>
                      <Form.Item.Label>Beschreibung</Form.Item.Label>
                      <Input.Textarea
                        {...form.register(`membership_template_version.discounts.${i}.description`, { required: true })}
                      />

                      {form.formState.errors.membership_template_version?.discounts?.[i]?.description && (
                        <small className="text-red-500">Pflichtfeld</small>
                      )}
                    </Form.Item>

                    <Form.Item $first $dense>
                      <Form.Item.Label>Template</Form.Item.Label>
                      <SelectInput
                        name={`membership_template_version.discounts.${i}.templateType`}
                        control={form.control}
                        placeholder="Typ auswählen"
                        items={[
                          {
                            label: 'Default',
                            value: 'Default',
                          },
                          {
                            label: 'Spezial',
                            value: 'Special',
                          },
                        ]}
                      />
                      {form.watch(`membership_template_version.discounts.${i}.templateType`) && (
                        <SelectInput
                          name={`membership_template_version.discounts.${i}.templateId`}
                          placeholder="Template auswählen"
                          control={form.control}
                          rules={{
                            validate: (v) => {
                              return !generalTemplates?.find(
                                (t) => t.id === form.watch(`membership_template_version.discounts.${i}.templateId`),
                              )?.renewal
                                ? 'Bitte Template mit automatischer Verlängerung wählen'
                                : true;
                            },
                          }}
                          items={generalTemplates
                            ?.filter(
                              (t) => t.type === form.watch(`membership_template_version.discounts.${i}.templateType`),
                            )
                            .map((t) => ({ label: t.name, value: t.id }))}
                        />
                      )}

                      {form.formState.errors.membership_template_version?.discounts?.[i]?.templateId && (
                        <small className="text-red-500">
                          {form.formState.errors.membership_template_version?.discounts?.[i]?.templateId.message}
                        </small>
                      )}
                    </Form.Item>

                    <Form.Item $first $dense>
                      <Form.Item.Label>Preis</Form.Item.Label>
                      <Controller
                        name={`membership_template_version.discounts.${i}.price`}
                        control={form.control}
                        render={({ field }) => (
                          <div className="relative rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span className="text-gray-500 sm:text-sm">
                                <CurrencyEuroIcon className="w-4 h-4" />
                              </span>
                            </div>

                            <NumericFormat
                              placeholder="–"
                              defaultValue={field.value / 100}
                              // value={field.value}
                              onValueChange={(e) => {
                                field.onChange(euroToCent(e.floatValue));
                              }}
                              onBlur={field.onBlur}
                              name={field.name}
                              // getInputRef={ref => { if (ref) ref.value = hidden.current?.value }}
                              className="block w-full dark:bg-gray-700 rounded-md border-gray-300 dark:border-gray-500 pl-9 pr-24 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              decimalSeparator=","
                              suffix={' €'}
                              decimalScale={2}
                            />
                          </div>
                        )}
                      />

                      {form.formState.errors.membership_template_version?.discounts?.[i]?.proce && (
                        <small className="text-red-500">Pflichtfeld</small>
                      )}
                    </Form.Item>

                    {form.watch(`membership_template_version.discounts.${i}.discountType`) === 'youth' ? (
                      <Form.Item $first $dense>
                        <Form.Item.Label>Alter</Form.Item.Label>
                        <Input
                          type="text"
                          {...form.register(`membership_template_version.discounts.${i}.age`, { required: true })}
                        />

                        {form.formState.errors.membership_template_version?.discounts?.[i]?.age && (
                          <small className="text-red-500">Pflichtfeld</small>
                        )}
                      </Form.Item>
                    ) : (
                      <>
                        <Form.Item $first $dense>
                          <Form.Item.Label>Dauer</Form.Item.Label>
                          <Input.TimeInterval
                            // overrideOptions={[
                            //   { label: 'Woche/n', value: 'W' },
                            //   { label: 'Monat/e', value: 'M' },
                            // ]}
                            {...form.register(`membership_template_version.discounts.${i}.duration`, {
                              required: true,
                              validate: (value) => {
                                if (value == 'PM') return 'Pflichtfeld';
                                return true;
                              },
                              setValueAs: (v) => (v === 'NULL' ? null : v),
                            })}
                          />

                          {form.formState.errors.membership_template_version?.discounts?.[i]?.duration && (
                            <small className="text-red-500">Pflichtfeld</small>
                          )}
                        </Form.Item>
                        <Form.Item $first $dense>
                          <Form.Item.Label>
                            Mindestalter
                            <Tooly label="Mindestalter, mit dem der Rabatt ausgewählt und gebucht werden darf. ">
                              <QuestionMarkCircleIcon className="w-4 h-4" />
                            </Tooly>
                          </Form.Item.Label>
                          <Input
                            type="number"
                            {...form.register(`membership_template_version.discounts.${i}.minAge`, {
                              valueAsNumber: true,
                            })}
                          />
                        </Form.Item>
                      </>
                    )}
                  </Field>
                )}
              </Form.Item.SortableList>
            </div>
          </Column>

          {typeof form.watch('membership_template_version.discountIntervalPrice') === 'number' &&
          form.watch('membership_template_version.discountIntervalPrice') >= 0 ? (
            <Column title="Aliquote Aktionsbuchung">
              <SelectInput
                name="membership_template_version.hectorSettings.aliquote"
                control={form.control}
                items={[
                  {
                    label: 'Anteiliger Monat ab Testphase + voller Aktionszeitraum',
                    value: "{ key: 'full', title: 'Anteiliger Monat ab Testphase + voller Aktionszeitraum' }",
                  },
                  {
                    label: 'Voller Aktionszeitraum (ab nächsten Monatsersten)',
                    value: "{ key: 'partial', title: 'Voller Aktionszeitraum (ab nächsten Monatsersten)' }",
                  },
                ]}
              />
            </Column>
          ) : null}

          <Column title="Inkludierte Leistungen*">
            <div className="col-span-2">
              <Form.Item.SortableList
                form={form}
                name="membership_template_version.mainText"
                maxItems={1}
                appendLabel="Leistung hinzufügen"
                editable={false}
                extraAction={
                  <Tooly label={templateTexts?.config.mainText?.map((t) => t.showWhenSet ?? []).join(', ')}>
                    <Button
                      $disabled={
                        templateTexts?.config.mainText?.filter((t) => {
                          const a =
                            t.showWhenSet?.map((s) => {
                              return form.watch(`membership_template_version.${s}`);
                            }) ?? [];
                          const allSet = a.every((b) => b);
                          return allSet;
                        }).length === 0
                      }
                      onClick={() => {
                        templateTexts?.config.mainText
                          ?.filter((texts: any) => {
                            const a =
                              texts.showWhenSet?.map((s: any) => {
                                return form.watch(`membership_template_version.${s}`);
                              }) ?? [];

                            const allSet = a?.every((b) => b);
                            return allSet;
                          })
                          .forEach((text) => {
                            form.setValue('membership_template_version.mainText.0', {
                              title: replace(text.title),
                              body: replace(text.body),
                            });
                          });
                      }}
                    >
                      <SquaresPlusIcon className="h-5 w-5 mr-2" />
                      Standard Leistung einfügen
                    </Button>
                  </Tooly>
                }
              >
                {(i) => (
                  <Field label="" className="p-6">
                    <Form.Item $first $dense>
                      <Form.Item.Label>Title</Form.Item.Label>
                      <Input
                        type="text"
                        {...form.register(`membership_template_version.mainText.${i}.title`, { required: true })}
                      />

                      {form.formState.errors.membership_template_version?.mainText?.[i]?.title && (
                        <small className="text-red-500">Pflichtfeld</small>
                      )}
                    </Form.Item>

                    <Form.Item $first $dense>
                      <Form.Item.Label>Beschreibung</Form.Item.Label>
                      <Input.Textarea
                        rows={3}
                        {...form.register(`membership_template_version.mainText.${i}.body`, { required: true })}
                      />
                      {form.formState.errors.membership_template_version?.mainText?.[i]?.body && (
                        <small className="text-red-500">Pflichtfeld</small>
                      )}
                    </Form.Item>
                  </Field>
                )}
              </Form.Item.SortableList>
            </div>
          </Column>

          <Column title="Gebühren">
            <div className="col-span-2">
              <Form.Item.SortableList
                form={form}
                name="membership_template_version.fees"
                append={
                  userCan.edit && {
                    title: 'Neue Gebühr',
                    body: '',
                    name: 'Neue Gebühr',
                    price: 0,
                    interval: 'P6M',
                    due: 'nach Testphase',
                  }
                }
                appendLabel="Gebühr hinzufügen"
                extraAction={
                  <Button onClick={() => setShowFeePicker(true)}>
                    <SquaresPlusIcon className="h-5 w-5 mr-2" />
                    Standard Gebühr hinzufügen
                  </Button>
                }
              >
                {(i) => (
                  <Field className="p-6">
                    <Form.Item $first $dense>
                      <Form.Item.Label>Name</Form.Item.Label>
                      <Input
                        type="text"
                        {...form.register(`membership_template_version.fees.${i}.name`, { required: true })}
                      />

                      {form.formState.errors.membership_template_version?.fees?.[i]?.name && (
                        <small className="text-red-500">Pflichtfeld</small>
                      )}
                    </Form.Item>

                    <div className="flex gap-3">
                      <Form.Item $first $dense>
                        <Form.Item.Label>Preis / Intervall</Form.Item.Label>
                        <Input.PricePerInterval
                          price={form.register(`membership_template_version.fees.${i}.price`, {
                            valueAsNumber: true,
                            required: true,
                          })}
                          interval={form.register(`membership_template_version.fees.${i}.interval`, {
                            setValueAs: (v) => (v === 'NULL' ? null : v),
                            // required: true,
                          })}
                          overrideOptions={hectorIntervals}
                        />
                        {(form.formState.errors.membership_template_version?.fees?.[i]?.interval ||
                          form.formState.errors.membership_template_version?.fees?.[i]?.price) && (
                          <small className="text-red-500">Pflichtfeld</small>
                        )}
                      </Form.Item>
                      <Form.Item $first $dense>
                        <Form.Item.Label>Erste Abbuchung</Form.Item.Label>
                        <SelectInput
                          control={form.control}
                          name={`membership_template_version.fees.${i}.due`}
                          rules={{ required: true }}
                          items={[
                            { label: 'nach Testphase', value: 'nach Testphase' },
                            { label: 'nach 3 Monaten', value: 'nach 3 Monaten' },
                            { label: 'nach 6 Monaten', value: 'nach 6 Monaten' },
                            { label: 'nach 12 Monaten', value: 'nach 12 Monaten' },
                            { label: 'bei Abschluss (nicht empfohlen)', value: 'bei Abschluss' },
                          ]}
                        />
                        {/* <Input */}
                        {/*   type="text" */}
                        {/*   {...form.register(`membership_template_version.fees.${i}.due`, { required: true })} */}
                        {/* /> */}
                        {form.formState.errors.membership_template_version?.fees?.[i]?.due && (
                          <small className="text-red-500">Pflichtfeld</small>
                        )}
                      </Form.Item>
                    </div>
                    <Form.Item $first $dense>
                      <Form.Item.Label>Titel</Form.Item.Label>
                      <Input
                        type="text"
                        {...form.register(`membership_template_version.fees.${i}.title`, { required: true })}
                      />

                      {form.formState.errors.membership_template_version?.fees?.[i]?.title && (
                        <small className="text-red-500">Pflichtfeld</small>
                      )}
                    </Form.Item>
                    <Form.Item $first $dense>
                      <Form.Item.Label>Beschreibung</Form.Item.Label>
                      <Input.Textarea
                        {...form.register(`membership_template_version.fees.${i}.body`, { required: true })}
                      />
                      {form.formState.errors.membership_template_version?.fees?.[i]?.body && (
                        <small className="text-red-500">Pflichtfeld</small>
                      )}
                    </Form.Item>
                  </Field>
                )}
              </Form.Item.SortableList>
            </div>
            <Modal open={showFeePicker} onClose={() => setShowFeePicker(false)} className="max-w-4xl">
              <div className="flex flex-wrap justify-between">
                <div className="text-lg mb-3">Standard Gebühr hinzufügen</div>
                <Button.Action onClick={() => setShowFeePicker(false)}>
                  <XMarkIcon className="w-4 h-4" />
                </Button.Action>
              </div>
              <div className="flex gap-3">{defaultFees}</div>
            </Modal>
          </Column>

          <Column title="ADD-ON">
            <div className="col-span-2">
              <Form.Item.SortableList
                form={form}
                name="membership_template_version.addons"
                append={
                  userCan.edit && {
                    name: 'Neues Addon',
                    description: '',
                    price: 0,
                    interval: 'P1M',
                    trialPeriod: 'P4W',
                    displayPrice: undefined,
                    displayInterval: 'P1W',
                  }
                }
                appendLabel="ADD-ON hinzufügen"
                extraAction={
                  <Button onClick={() => setShowAddonPicker(true)}>
                    <SquaresPlusIcon className="h-5 w-5 mr-2" />
                    Standard ADD-ON hinzufügen
                  </Button>
                }
              >
                {(i) => (
                  <Field label="" className="p-6">
                    <Form.Item $first $dense>
                      <Form.Item.Label>Name</Form.Item.Label>
                      <Input
                        type="text"
                        {...form.register(`membership_template_version.addons.${i}.name`, { required: true })}
                      />

                      {form.formState.errors.membership_template_version?.addons?.[i]?.name && (
                        <small className="text-red-500">Pflichtfeld</small>
                      )}
                    </Form.Item>

                    <div className="flex gap-3">
                      <Form.Item $first $dense>
                        <Form.Item.Label>Preis / Intervall</Form.Item.Label>
                        <Input.PricePerInterval
                          price={form.register(`membership_template_version.addons.${i}.price`, {
                            valueAsNumber: true,
                            onChange: (v) => {
                              const [, stringNum, unit] =
                                form.watch(`membership_template_version.addons.${i}.interval`) ?? [];
                              const amount = Number(stringNum);
                              let displayPrice = v.target.value;
                              if (unit === 'D') {
                                displayPrice = (v.target.value / amount) * 7;
                              }
                              if (unit === 'W') {
                                displayPrice = v.target.value / amount;
                              }
                              if (unit === 'M') {
                                displayPrice = v.target.value / amount / 4.33;
                              }
                              if (unit === 'Y') {
                                displayPrice = v.target.value / amount / 12 / 4.33;
                              }

                              form.setValue(`membership_template_version.addons.${i}.displayPrice`, displayPrice, {
                                shouldDirty: true,
                                shouldTouch: true,
                                shouldValidate: true,
                              });
                              return true;
                            },
                            required: true,
                          })}
                          interval={form.register(`membership_template_version.addons.${i}.interval`, {
                            setValueAs: (v) => (v === 'NULL' ? null : v),
                            onChange: (v) => {
                              const [, stringNum, unit] = v.target.value ?? [];
                              const amount = Number(stringNum);

                              const price = Number(form.watch(`membership_template_version.addons.${i}.price`));
                              let displayPrice = price;

                              if (unit === 'D') {
                                displayPrice = (price / amount) * 7;
                              }
                              if (unit === 'W') {
                                displayPrice = price / amount;
                              }
                              if (unit === 'M') {
                                displayPrice = price / amount / 4.33;
                              }
                              if (unit === 'Y') {
                                displayPrice = price / amount / 12 / 4.33;
                              }

                              form.setValue(`membership_template_version.addons.${i}.displayPrice`, displayPrice, {
                                shouldDirty: true,
                                shouldTouch: true,
                                shouldValidate: true,
                              });
                              return true;
                            },

                            required: true,
                          })}
                          overrideOptions={[
                            { label: 'pro Tag', value: 'P1D' },
                            { label: 'pro Woche', value: 'P1W' },
                            { label: '2-wöchentlich', value: 'P2W' },
                            { label: '4-wöchentlich', value: 'P4W' },
                            { label: 'pro Kalendermonat', value: 'P1M' },
                            { label: 'pro Vierteljahr', value: 'P3M' },
                            { label: 'pro Halbjahr', value: 'P6M' },
                            { label: 'pro Jahr', value: 'P1Y' },
                          ]}
                        />
                        {(form.formState.errors.membership_template_version?.addons?.[i]?.interval ||
                          form.formState.errors.membership_template_version?.addons?.[i]?.price) && (
                          <small className="text-red-500">Pflichtfeld</small>
                        )}
                      </Form.Item>

                      <Form.Item $first $dense>
                        <Form.Item.Label>Anzeige Preis / Intervall</Form.Item.Label>
                        <div className="pointer-events-none opacity-50">
                          <Input.PricePerInterval
                            key={`${form.watch(`membership_template_version.addons.${i}.price`)}/${form.watch(
                              `membership_template_version.addons.${i}.interval`,
                            )}`}
                            price={form.register(`membership_template_version.addons.${i}.displayPrice`, {
                              valueAsNumber: true,
                              required: true,
                            })}
                            interval={form.register(`membership_template_version.addons.${i}.displayInterval`, {
                              setValueAs: (v) => (v === 'NULL' ? null : v),
                              required: true,
                            })}
                          />
                        </div>
                        {(form.formState.errors.membership_template_version?.addons?.[i]?.displayInterval ||
                          form.formState.errors.membership_template_version?.addons?.[i]?.displayPrice) && (
                          <small className="text-red-500">Pflichtfeld</small>
                        )}
                      </Form.Item>
                    </div>
                    <Form.Item $first $dense>
                      <Form.Item.Label>Testphase</Form.Item.Label>
                      <Input.TimeInterval
                        overrideOptions={[
                          { label: 'Tag/e', value: 'D' },
                          { label: 'Woche/n', value: 'W' },
                          { label: 'Monat/e', value: 'M' },
                        ]}
                        {...form.register(`membership_template_version.addons.${i}.trialPeriod`)}
                      />
                    </Form.Item>
                    <Form.Item $first $dense>
                      <Form.Item.Label>Beschreibung</Form.Item.Label>
                      <Input.Textarea {...form.register(`membership_template_version.addons.${i}.description`)} />
                    </Form.Item>
                  </Field>
                )}
              </Form.Item.SortableList>
            </div>

            <Modal open={showAddonPicker} className="max-w-4xl" onClose={() => setShowAddonPicker(false)}>
              <div className="flex justify-between flex-wrap">
                <div className="text-lg mb-3">Standard ADD-ON hinzufügen</div>
                <Button.Action onClick={() => setShowAddonPicker(false)}>
                  <XMarkIcon className="w-4 h-4" />
                </Button.Action>
              </div>
              <div className="flex gap-3 flex-wrap mb-3">
                {defaultAddons?.map((addon, i) => {
                  return (
                    <Button
                      key={i}
                      $primary={addonQueue[addon.name]}
                      onClick={() => {
                        setAddonQueue((prev) => {
                          if (addonQueue[addon.name]) {
                            delete prev[addon.name];
                            return { ...prev };
                          }

                          return {
                            ...prev,
                            [addon.name]: () => {
                              form.setValue(
                                'membership_template_version.addons',
                                [...form.watch('membership_template_version.addons'), addon],
                                { shouldDirty: true },
                              );
                            },
                          };
                        });
                      }}
                    >
                      {addon.name}
                    </Button>
                  );
                })}
              </div>
              <div className="flex justify-end">
                <Button
                  $primary
                  onClick={async () => {
                    for (const fn in addonQueue) {
                      if (addonQueue[fn]) {
                        await addonQueue[fn]();
                      }
                    }
                    setShowAddonPicker(false);
                    setAddonQueue({});
                  }}
                >
                  ADD-ONs hinzufügen
                </Button>
              </div>
            </Modal>
          </Column>

          <Column title="Vorteile">
            <div className="col-span-2">
              <Form.Item.SortableList
                form={form}
                name="membership_template_version.benefits"
                append={userCan.edit && { title: 'Neuer Vorteil', description: 'Beschreibung des Vorteils' }}
                appendLabel="Vorteil hinzufügen"
                extraAction={
                  <Button
                    $disabled={
                      templateTexts?.config.benefits?.filter((t) => {
                        const a = t.showWhenSet?.map((s) => {
                          return form.watch(`membership_template_version.${s}`);
                        });
                        const allSet = a?.every((b) => b);
                        return allSet;
                      }).length === 0
                    }
                    $primary={form.formState.isDirty}
                    onClick={defaultVorteile}
                  >
                    <ArrowPathIcon className="h-5 w-5 mr-2" />
                    Vorteile aktualisieren {form.formState.isDirty && '(Konfiguration wurde geändert)'}
                  </Button>
                }
              >
                {(i) => (
                  <>
                    <div className="grid grid-cols-3 gap-2 p-6">
                      <Form.Item $dense $first className="col-span-3">
                        <Form.Item.Label>Titel</Form.Item.Label>
                        <Input type="text" {...form.register(`membership_template_version.benefits.${i}.title`)} />
                      </Form.Item>
                      <Form.Item $dense $first className="col-span-3">
                        <Form.Item.Label>Beschreibung</Form.Item.Label>
                        <Controller
                          name={`membership_template_version.benefits.${i}.description`}
                          control={form.control}
                          render={({ field }) => (
                            <MakroEditor value={field.value} onChange={field.onChange} category="mw-benefit" />
                          )}
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
              </Form.Item.SortableList>
            </div>
          </Column>
        </>
      )}

      {data?.membership_template_version?.draftStatus !== null && userCan.edit && (
        <>
          <div className="flex p-4 pb-6 bg-white dark:bg-gray-700 sticky bottom-0 justify-end">
            <Button
              $disabled={!isDirty || !form.formState.isValid}
              $primary
              loading={pending}
              onClick={form.handleSubmit(onSubmit)}
            >
              Speichern
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const Column = ({ children, border = true, title }: { children: ReactNode; border?: boolean; title: string }) => (
  <div className={classNames('grid grid-cols-[1fr_2fr] gap-6 pb-6 my-6', border && 'border-b')}>
    <div className="font-semibold text- dark:text-gray-400 text-gray-700">{title}</div>
    <div className="grid grid-cols-2 gap-3">{children}</div>
  </div>
);

const Field = ({ label, children, className }: any) => (
  <Form.Item $first $dense className={className}>
    <Form.Item.Label>{label}</Form.Item.Label>
    {children}
  </Form.Item>
);

nunjucks.configure({
  autoescape: true,
  throwOnUndefined: true,
  trimBlocks: true,
  lstripBlocks: true,
  noCache: true,
});
const nun = new nunjucks.Environment({
  autoescape: true,
  throwOnUndefined: true,
  trimBlocks: true,
  lstripBlocks: true,
  noCache: true,
});
if (nun) {
  nun?.addFilter('price', function (str) {
    if (str) return formatPrice(str);
    return '-';
  });

  nun?.addFilter('period', function (str) {
    if (str == 'PW') return '-';
    if (str) return formatPeriod(str);
    return '-';
  });

  nun?.addFilter('reccurring', function (str) {
    if (str == 'PW') return '-';
    if (str) return formatRecurring(str);
    return '-';
  });
  nun?.addFilter('reccuring', function (str) {
    if (str == 'PW') return '-';
    if (str) return formatRecurring(str);
    return '-';
  });
}

const insertVariables = (variables: any) => (text: string) => {
  const a = document.createElement('div');
  a.innerHTML = text; // stripping html tags
  return nun.renderString(a.textContent, variables, {});
};
